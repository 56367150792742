import { autobind } from "core-decorators";
import { Inject } from "typescript-ioc";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";

import "../Styles/SiteLinks.css";

export class SiteLinks extends UiComponent {
    public get key(): string {
        return "SiteLinks";
    }

    private readonly SEL_SITELINKS_INPUT: string = ".sitelinks__input";
    private readonly SEL_SITELINKS_INPUT_CHECKED: string = ".sitelinks__input:checked";
    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        this.bindEvents();
    }

    private bindEvents(): void {
        this.bindDelegatedClick(this.SEL_SITELINKS_INPUT, this._onSiteLinksLabelClick);
    }

    @autobind
    _onSiteLinksLabelClick(event: JQuery.TriggeredEvent): void {
        // close open inputs
        if ((event.target as HTMLInputElement).checked) {
            this.find(this.SEL_SITELINKS_INPUT_CHECKED).forEach(
                (input: HTMLInputElement) => (input.checked = input === event.target)
            );
        }
    }
}
