import { Inject } from "typescript-ioc";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";

export class WeChat extends UiComponent {
    public get key(): string {
        return "WeChat";
    }

    private readonly SEL_WECHAT_QR_POPUP: string = "#wechat-qr-popup";

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        const wechatQRPopup = document.querySelector(this.SEL_WECHAT_QR_POPUP);
        if (wechatQRPopup) {
            this.bindClick(() => {
                kendo.createWindow(wechatQRPopup).open();
            });
        }
    }
}
