import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { Footer } from "./Footer";

import "../Styles/Footer.css";

Container.get(ReadyHelper).bindDomReady(() =>
    Container.get(UiComponentFactory).createBase(Footer, ".page-footer")
);
