import { autobind } from "core-decorators";
import { Inject } from "typescript-ioc";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { WeChat } from "./WeChat";
import { LangPopupSwitcher } from "./LangPopupSwitcher";
import { SiteLinks } from "./SiteLinks";
import { CookieService } from "Cookies/Scripts/CookieService";
import { scheduleCacheCleanup } from "Shopping/Scripts/utils";

import "../Styles/Footer.css";

export class Footer extends UiComponent {
    public get key(): string {
        return "Footer";
    }

    private readonly COOKIE_VIABILITY_IN_DAYS: number = 365;
    private readonly DATA_POPUP_LANGUAGE_COOKIE_KEY: string = "popupLanguageCookie";
    private readonly DATA_LANGUAGE_COOKIE_KEY: string = "languageCookie";
    private readonly SEL_WECHAT_BUTTON: string = ".js-button-wechat";
    private readonly SEL_INACTIVE_LANG_COUNTRY: string = ".w-lang-country-select a:not(.active)";
    private readonly SEL_POPUP_LANG_SWITCHER: string = ".ui-popup-lang-switcher";
    private readonly SEL_SITELINKS: string = ".sitelinks";
    private readonly TIMEOUT: number = 200;

    private readonly _cookieService: CookieService;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject cookieService: CookieService
    ) {
        super(componentFactory, binder, loggerFactory);
        this._cookieService = cookieService;
    }

    public init(): void {
        this.bindEvents();
        this._initWechatQR();
        this._initLangPopupSwitcher();
        this._initSiteLinks();
        this._cookieService.setRaw(true);
    }

    private bindEvents(): void {
        this.bindDelegatedClick(this.SEL_INACTIVE_LANG_COUNTRY, this._onLangChange);
    }

    private _initLangPopupSwitcher(): void {
        if (this.findElement(this.SEL_POPUP_LANG_SWITCHER)) {
            this.createComponent(LangPopupSwitcher, this.SEL_POPUP_LANG_SWITCHER);
        }
    }

    private _initWechatQR(): void {
        if (this.findElement(this.SEL_WECHAT_BUTTON)) {
            this.createComponent(WeChat, this.SEL_WECHAT_BUTTON);
        }
    }

    private _initSiteLinks(): void {
        if (this.findElement(this.SEL_SITELINKS)) {
            this.createComponent(SiteLinks, this.SEL_SITELINKS);
        }
    }

    private _updateLangCookie(eventTarget: HTMLElement): void {
        const dataset = eventTarget.dataset;

        const langCookieName = dataset[this.DATA_LANGUAGE_COOKIE_KEY];
        const popupLangCookieName = dataset[this.DATA_POPUP_LANGUAGE_COOKIE_KEY] || "";
        const clickedLangFlag = dataset.languageCode;
        const popupLangCookieValue = this._cookieService.getCookie(popupLangCookieName);

        if (langCookieName) {
            this._cookieService.setCookie(
                langCookieName,
                clickedLangFlag,
                this.COOKIE_VIABILITY_IN_DAYS
            );
        }

        if (popupLangCookieName && popupLangCookieValue) {
            this._cookieService.setCookie(
                popupLangCookieName,
                clickedLangFlag,
                this.COOKIE_VIABILITY_IN_DAYS
            );
        }
    }

    @autobind
    _onLangChange(event: JQuery.TriggeredEvent): void {
        event.preventDefault();

        if (!event.target) {
            return;
        }

        const el = event.target as HTMLElement;
        const link = el.dataset.href;

        this._updateLangCookie(el);

        scheduleCacheCleanup();

        if (ori.burgerMenu) {
            ori.burgerMenu.clearSessionStorage();
        }

        if (ori.allReportsPage) {
            ori.allReportsPage.clearStorageItems();
        }

        window.setTimeout(() => {
            window.open(link, "_self");
        }, this.TIMEOUT);
    }
}
